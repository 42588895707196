// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyDYZieS_V9JWFdI7-388bhr6mB4BYf4Jpo",
    authDomain: "comeing-soon.firebaseapp.com",
    projectId: "comeing-soon",
    storageBucket: "comeing-soon.appspot.com",
    messagingSenderId: "631490494536",
    appId: "1:631490494536:web:ba288137edb2767fa5187d",
    measurementId: "G-C9LX9C558J"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
