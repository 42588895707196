// src/components/LoadingScreen.js

import React from 'react';
import './LoadingScreen.css'; // We'll create this CSS file later

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loading-spinner"></div>
      <p>Loading...</p>
    </div>
  );
};

export default LoadingScreen;
