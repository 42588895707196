// src/components/SubscriptionForm.js

import React, { useState } from 'react';
import { collection, query, where, getDocs, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../firebase';
import './SubscriptionForm.css'; // Create this CSS file

const SubscriptionForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState({ text: '', type: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = (email) => {
    const re = /^\S+@\S+\.\S+$/;
    return re.test(email);
  };

  const displayMessage = (text, type) => {
    setMessage({ text, type });
    setTimeout(() => {
      setMessage({ text: '', type: '' });
    }, 5000); // Hide message after 5 seconds
  };

  const handleSubscribe = async () => {
    if (!validateEmail(email)) {
      displayMessage('Please enter a valid email address.', 'error');
      return;
    }

    setIsSubmitting(true);

    try {
      // Check for duplicate email
      const subscribersRef = collection(db, "subscribers");
      const q = query(subscribersRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        displayMessage('This email is already subscribed.', 'error');
      } else {
        // Add new subscriber
        await addDoc(subscribersRef, {
          email: email,
          subscribed_at: serverTimestamp(),
        });
        displayMessage("Thank you for subscribing! We'll keep you updated.", 'success');
        setEmail('');
      }
    } catch (error) {
      console.error("Error adding document: ", error);
      displayMessage('There was an error processing your request. Please try again later.', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="subscription-form">
      <input 
        type="email" 
        placeholder="Enter your email" 
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={isSubmitting}
      />
      <button onClick={handleSubscribe} disabled={isSubmitting}>
        {isSubmitting ? 'Subscribing...' : 'Stay Updated'}
      </button>
      {message.text && (
        <div className={`message ${message.type}`}>
          {message.text}
        </div>
      )}
    </div>
  );
};

export default SubscriptionForm;
