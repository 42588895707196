// src/App.js

import React, { useState, useEffect } from 'react';
import LoadingScreen from './components/LoadingScreen';
import Countdown from './components/Countdown';
import SubscriptionForm from './components/SubscriptionForm';
import SocialMediaLinks from './components/SocialMediaLinks';
import Footer from './components/Footer';
import CustomCursor from './components/CustomCursor';
import './App.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time or perform actual initialization tasks
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // 2 seconds delay for demonstration

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}
      <CustomCursor />
      <div className="container">
        <div className="logo">
          <img src="/logo.png" alt="Absolx Logo" />
        </div>
        <h1>Innovating the Future with AI</h1>
        <Countdown />
        <SubscriptionForm />
        <SocialMediaLinks />
        <Footer />
      </div>
    </>
  );
}

export default App;
