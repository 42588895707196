// src/components/CustomCursor.js

import React, { useEffect } from 'react';
import './CustomCursor.css'; // Create this CSS file

const CustomCursor = () => {
  useEffect(() => {
    const cursor = document.getElementById('custom-cursor');

    const moveCursor = (e) => {
      if (cursor) {
        cursor.style.left = `${e.clientX}px`;
        cursor.style.top = `${e.clientY}px`;
      }
    };

    document.addEventListener('mousemove', moveCursor);

    return () => {
      document.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  return <div id="custom-cursor"></div>;
};

export default CustomCursor;
