// src/components/SocialMediaLinks.js

import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import './SocialMediaLinks.css'; // Create this CSS file

const SocialMediaLinks = () => {
  return (
    <div className="social-media">
      <a 
        href="https://www.facebook.com/people/Absol-x-Core-AI/100094233824766/" 
        className="social-icon magnetic"
        target="_blank" 
        rel="noopener noreferrer"
      >
        <FaFacebookF />
      </a>
      <a 
        href="https://www.instagram.com/absol_x_core.ai/" 
        className="social-icon magnetic"
        target="_blank" 
        rel="noopener noreferrer"
      >
        <FaInstagram />
      </a>
      <a 
        href="https://lk.linkedin.com/company/absol-x" 
        className="social-icon magnetic"
        target="_blank" 
        rel="noopener noreferrer"
      >
        <FaLinkedinIn />
      </a>
    </div>
  );
};

export default SocialMediaLinks;
