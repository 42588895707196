// src/components/Countdown.js

import React, { useState, useEffect } from 'react';
import './Countdown.css'; // Create this CSS file

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState({});
  const [message, setMessage] = useState('');

  const targetDate = new Date('October 30, 2024 00:00:00').getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = targetDate - now;

    let timeLeftObj = {};

    if (difference > 0) {
      timeLeftObj = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        ),
        seconds: Math.floor((difference % (1000 * 60)) / 1000)
      };

      if (difference <= 1800000) { // 30 minutes in milliseconds
        setMessage("Coming Soon in 30 Minutes!");
      } else {
        setMessage("");
      }
    } else {
      timeLeftObj = null;
      setMessage("We're Live!");
    }

    return timeLeftObj;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const tLeft = calculateTimeLeft();
      setTimeLeft(tLeft);

      if (!tLeft) clearInterval(timer);
    }, 1000);

    // Initialize countdown immediately
    const initialTimeLeft = calculateTimeLeft();
    setTimeLeft(initialTimeLeft);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="countdown-container">
      {timeLeft ? (
        <>
          <div className="countdown">
            <div className="time-segment">
              <span className="number">{timeLeft.days}</span>
              <span className="label">Days</span>
            </div>
            <div className="time-segment">
              <span className="number">{timeLeft.hours}</span>
              <span className="label">Hours</span>
            </div>
            <div className="time-segment">
              <span className="number">{timeLeft.minutes}</span>
              <span className="label">Minutes</span>
            </div>
            <div className="time-segment">
              <span className="number">{timeLeft.seconds}</span>
              <span className="label">Seconds</span>
            </div>
          </div>
          {message && <h2 className="coming-soon-message">{message}</h2>}
        </>
      ) : (
        <h2 className="coming-soon-message">{message}</h2>
      )}
    </div>
  );
};

export default Countdown;
